import React from "react";
import { Script } from "gatsby";

const RootElement = ({ children }) => {
  const ga4Id = "G-Y7NM95L7RF";
  return (
    <>
      <Script id="gtag-load" src={`https://www.googletagmanager.com/gtag/js?id=${ga4Id}`} />
      <Script id="gtag-config">
        {`
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments); }
          gtag("js", new Date());
          gtag("config", "${ga4Id}", {cookie_flags: "SameSite=None;Secure"});
        `}
      </Script>

      <div>{children}</div>
    </>
  );

};

export default RootElement;
